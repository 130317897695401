// -- The HTML Serializer
// This function will be used to modify the way that a Rich Text or Title field is rendered.

import React from "react"
import { Link as PrismicLink } from "prismic-reactjs"
import { Elements } from "prismic-richtext"
import get from "lodash.get"
import { linkResolver } from "./linkResolver"
import Link from "gatsby-link"
import Text from "../components/text"

// -- Function to add unique key to props
const propsWithUniqueKey = function (props, key) {
  return Object.assign(props || {}, { key })
}

export default (options = {}) => (type, element, content, children, index) => {
  var props = {}
  const label = get(element, "data.label", "left")

  switch (type) {
    case Elements.heading2:
      return (
        <Text.H2 key={index} {...options}>
          {children}
        </Text.H2>
      )

    case Elements.heading3:
      return (
        <Text.H3 key={index} {...options}>
          {children}
        </Text.H3>
      )

    case Elements.heading4:
      return (
        <Text.H4 key={index} {...options}>
          {children}
        </Text.H4>
      )

    case Elements.heading5:
      return (
        <Text.H5 key={index} {...options}>
          {children}
        </Text.H5>
      )

    case Elements.heading6:
      return (
        <Text.H6 key={index} {...options}>
          {children}
        </Text.H6>
      )

    case Elements.paragraph:
      return (
        <Text.P key={index} {...options}>
          {children}
        </Text.P>
      )

    case Elements.label:
      return (
        <Text.Span key={index} {...options} textAlign={label}>
          {children}
        </Text.Span>
      )

    case Elements.strong:
      return (
        <Text.Strong key={index} {...options}>
          {children}
        </Text.Strong>
      )

    case Elements.em:
      return (
        <Text.Em key={index} {...options}>
          {children}
        </Text.Em>
      )

    case Elements.listItem:
      return (
        <Text.ListItem key={index} {...options}>
          {children}
        </Text.ListItem>
      )

    case Elements.oListItem:
      return (
        <Text.OListItem key={index} {...options}>
          {children}
        </Text.OListItem>
      )

    case Elements.list:
      return (
        <Text.List key={index} {...options}>
          {children}
        </Text.List>
      )

    case Elements.oList:
      return (
        <Text.List key={index} {...options} ordered>
          {children}
        </Text.List>
      )

    case Elements.hyperlink:
      let result = ""
      const url = PrismicLink.url(element.data, linkResolver)

      // Generate links to Prismic Documents as <Link> components
      if (element.data.link_type === "Document") {
        result = (
          <Link to={url} key={index}>
            {content}
          </Link>
        )
      } else {
        const target = element.data.target
          ? { target: element.data.target, rel: "noopener" }
          : {}
        result = (
          <Text.A
            key={index}
            href={url}
            {...options}
            {...target}
            activeIndicator
          >
            {content}
          </Text.A>
        )
      }
      return result

    case Elements.span:
      if (content) {
        return content.split("\n").reduce((acc, p) => {
          if (acc.length === 0) {
            return [p]
          } else {
            const brIndex = (acc.length + 1) / 2 - 1
            const br = React.createElement(
              "br",
              propsWithUniqueKey({}, brIndex)
            )
            return acc.concat([br, p])
          }
        }, [])
      } else {
        return null
      }

    default:
      return null
  }
}
